import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '../shared/service/dataprovider';

export default function Reports() {
  const { data, error } = useQuery({
    queryFn: () => api.get(`/v1/admin/metabase-link`),
  });
  return (
    <>
      <h2>Adoption Report(DownLoad Link at Bottom Right)</h2>
      <iframe src={data?.data?.url} width="100%" height="800px" />
    </>
  );
}
